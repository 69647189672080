<!--
 * @Description: 金刚位
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-05-11 16:55:32
 * @LastEditTime: 2020-08-24 15:13:43
 -->
<template>
  <div class="v-goods-mod" :style="getLayoutStyle">
    <div
      class="v-goods-hd goods-bgi"
      :style="{ 'background-image': 'url(' + component.base[2]['imgUrl'] + ')' }"
    >
      <div class="v-goods-info" v-if="isInHome === false">
        <h3 class="vg-title" :style="{ color: component.style[0]['val'] }">
          {{ component.base[0].val ? component.base[0].val : '标题' }}
        </h3>
        <div class="vg-desc" :style="{ color: component.style[1]['val'] }">
          {{ component.base[1].val ? component.base[1].val : '描述' }}
        </div>
      </div>
      <div class="v-goods-info" v-else>
        <h3 class="vg-title">{{ component.base[0].val ? component.base[0].val : '标题' }}</h3>
        <div class="vg-desc">{{ component.base[1].val ? component.base[1].val : '描述' }}</div>
      </div>
      <div class="v-goods-more">
        <a href="javascript:;">
          更多
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAByklEQVRIS83WsWsUQRgF8Pd273JXWCiIgmgrgo1WomhjGUGMngvHBsIpBFQINoIgqCCC/0DAcCCS3O3ichcEKzsrC9OKoELEWJo0NipMvicbs7pGoxfNgFPu7psfs9+3M8skSXYC9f1myzVJ87UaFqIo+ohNGux0em9J7JJQIekATgI2GceN15thsNvt35Z0EcDW1QkF4A3JLqD7cdyY/xeIeThNe4fMcAXgcUnbVic0Es8B3K1WrRdF0fu/gVaAYiTJbA7cAHBMUnFPJF4CuBzHjccbRX4A8nCSJNul+rBk4ySPlKC88E+DILi5uFifm5gY/jwI9hNQhNL04R7JzpqtQHtL0DKJthnbzn140Wq1Pv0OWhcoQp1OfzeJ85KuAwiK6187zu5Uq7V2FJ1cWA/5I5AHsywbci7YJwUXJDsHYKjUca8A3gvDMG02T71bCw0EFKEsy0LnKofNdI3UUQlbvnccnwGYco6PxsZOL31b6SCF+tUzMzP9E0GAWwAOlOpjAOfCMLjabI48yXMbWsFaaHp6dkelohEzjJM4WIKWAD4YHT1z6f8FvLwib0X22qbePjRvW4XXzc7bdu31wPF+ZHo/9H3/tnwBub1OKLOC1OsAAAAASUVORK5CYII="
            alt
          />
        </a>
      </div>
    </div>
    <div class="v-goods-bd">
      <div class="vg-list">
        <ul>
          <li v-for="(i, index) in component.action.config" :key="`${index}-${i.goodsId}`">
            <div class="vg-goods-img">
              <img
                v-if="
                  i.tempGoodsData &&
                  (i.tempGoodsData.item_id__white_image || i.tempGoodsData.item_id__pict_url)
                "
                :src="
                  i.tempGoodsData &&
                  (i.tempGoodsData.item_id__white_image || i.tempGoodsData.item_id__pict_url)
                "
              />
              <img v-else src="https://oss.zheyi360.com/app-image/topic/default-goods.png" />
            </div>
            <div class="vg-goods-commission">
              佣金{{
                i.tempGoodsData && i.tempGoodsData.show_commission_rate
                  ? i.tempGoodsData.show_commission_rate / 100
                  : 0
              }}%
            </div>
            <div class="vg-goods-price">
              ¥{{ i.tempGoodsData && i.tempGoodsData.show_price ? i.tempGoodsData.show_price : 0 }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VGoodsBang',
  props: {
    component: {
      type: Object,
    },
    isInHome: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      homeStyles: [
        {
          type: 'input-number',
          label: '容器外上',
          attr: 'margin-top',
          val: 10,
          unit: 'px',
          min: 0,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: '容器外右',
          attr: 'margin-right',
          val: 16,
          unit: 'px',
          min: 0,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: true,
        },
        {
          type: 'input-number',
          label: '容器外下',
          attr: 'margin-bottom',
          val: 10,
          unit: 'px',
          min: 0,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: true,
        },
        {
          type: 'input-number',
          label: '容器外左',
          attr: 'margin-left',
          val: 16,
          unit: 'px',
          min: 0,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: true,
        },
      ],
    };
  },
  computed: {
    getLayoutStyle() {
      const ret = [];
      const loopStyles = this.isInHome ? this.homeStyles : this.component.style;
      loopStyles.forEach(item => {
        const unit = item.unit || '';
        if (item.val && (item.attr.indexOf('margin') > -1 || item.attr.indexOf('padding') > -1)) {
          ret.push(item.attr + ':' + item.val + unit);
        }
      });
      return ret.join(';');
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.v-goods-mod {
  text-align: left;
  .v-goods-hd {
    display: flex;
    background: #fff;
    border-radius: 3px 3px 0px 0px;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 8px;
    .v-goods-info {
      height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .vg-title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        line-height: 100%;
      }
      .vg-desc {
        font-size: 12px;
        line-height: 100%;
        color: #999;
      }
    }
    .v-goods-more {
      height: 20px;
      line-height: 20px;
      background: #ffffff;
      border-radius: 10px;
      a,
      a:hover {
        display: flex;
        align-items: center;
        color: #999;
        padding: 0 8px;
        text-decoration: none;
        img {
          margin-left: 5px;
          width: 8px;
          height: 12px;
        }
      }
    }
    &.goods-bgi {
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .v-goods-bd {
    background: #fff;
    padding: 8px 0;
    border-radius: 0 0 3px 3px;
    .vg-list {
      overflow-x: auto;
      white-space: nowrap;
      ul {
        margin-bottom: 0;
        li {
          margin-right: 14px;
          display: inline-block;
          text-align: center;
          .vg-goods-img {
            padding-bottom: 8px;
            img {
              width: 84px;
              height: 84px;
              display: block;
            }
          }
          .vg-goods-commission {
            color: #f43324;
            font-size: 12px;
            line-height: 100%;
            font-weight: 600;
          }
          .vg-goods-price {
            font-size: 12px;
            color: #666666;
          }
          &:first-child {
            margin-left: 7px;
          }
        }
      }
    }
  }
}
</style>
