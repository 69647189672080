<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 白青
 * @Date: 2020-05-11 16:55:32
 * @LastEditTime: 2020-05-27 17:07:19
 -->
<template>
  <div :class="['comp-content', component.active ? 'active' : '']" :style="getStyle">
    <div class="image-box">
      <template v-for="item in component.action.config">
        <a v-if="item.type == 'link'" href="javascript:;" :class="['kuran-split-image', `kuran-split-image-${item.type}`]" :key="item.imgUrl" :style="getImageWidthStyle">
          <img v-if="item.imgUrl" :src="item.imgUrl" />
        </a>
        <a v-else href="javascript:;" :class="['kuran-split-image', `kuran-split-image-${item.type}`]" :key="item.imgUrl" :style="getImageWidthStyle">
          <img v-if="item.imgUrl" :src="item.imgUrl" />
        </a>
      </template>
      <!-- <div v-if="!component.action.config[0].imgUrl.length" class="image-placeholder"><i class="fa fa-image"></i></div> -->
      <div v-if="!component.action.config[0].imgUrl.length" class="image-placeholder">
        <i class="fa fa-image" v-for="i in this.component.base[0].val" :key="i"></i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'KuranSplitImage',
    props: {
      component: {
        type: Object
      }
    },
    data() {
      return {
        imgUrls: this.component.config,
        // split: this.component.base && this.component.base[0].val
      }
    },
    mounted () {
    },
    computed: {
      getStyle() {
        const ret = []
        this.component.style.forEach((item) => {
          const unit = item.unit || ''
          if (item.val) {
            if (item.attr === 'background-image') {
              ret.push(item.attr + ':url(' + item.val + ')')
            } else {
              ret.push(item.attr + ':' + item.val + unit)
            }
          }
        })
        return ret.join(';')
      },
      getImageWidthStyle() {
        const style = `width: calc(100% / ${this.component.base[0].val});`;
        return style;
      }
    },
    methods:{
      imgStyle(w, h){
        const _w = (750-60)/ this.component.base[0]['val'];
        const _h = _w * h / w;
        return `width:${_w/2}px;height:${_h/2}px`
      },
      // getStyle() {
      //   const ret = []
      //   this.component.style.forEach((item) => {
      //     const unit = item.unit || ''
      //     if (item.val) {
      //       if (item.attr === 'background-image') {
      //         ret.push(item.attr + ':url(' + item.val + ')')
      //       } else {
      //         ret.push(item.attr + ':' + item.val + unit)
      //       }
      //     }
      //   })
      //   return ret.join(';')
      // }
    }
    // watch: {
    //   component: {
    //     handler() {
    //       this.imgUrl = this.component.style[1].val
    //     },
    //     deep: true
    //   }
    // }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .image-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
 

    .image-placeholder {
      width: 100%;
      height: 100px;
      line-height: 100px;
      text-align: center;
      display: flex;
      justify-content: space-around;
      align-items: center;

      > .fa {
        color: #83c0ff;
        font-size: 40px;
      }
    }

    .kuran-split-image {
      display: inline-block;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      border: 0;
      -webkit-user-drag: none;
    }
  }
</style>
