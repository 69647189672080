<template>
  <div :class="['comp-content', 'kuran-floor-menu', component.active ? 'active' : '']" :style="{ paddingTop, paddingRight, paddingBottom, paddingLeft }">
    <div id="floor" class="floor">
      <input type="hidden" :value="borderRadius" />
      <div class="floor-placeholder"></div>
      <div class="floor-inner">
        <div class="floor-tabs" :style="{ backgroundColor, borderRadius, paddingRight: this.component.base[0].val ? '40px' : '0' }">
          <div class="floor-tabs-inner">
            <div class="tabs-scroll-list">
              <!-- `#${item.click ? item.click.href : ''}` -->
              <a
                :class="['tabs-nav', { active: idx == 0 }]"
                href="javascript:;"
                :style="getItemStyle(idx)"
                v-for="(item, idx) in items"
                :key="idx"
              >
                <span class="icon" :style="`display: ${idx == 0 ? 'inline-block' : 'none'};`">&#xe625;</span>
                {{item.text}}
              </a>
              <!-- <a class="tabs-nav" href="#floor1">楼层楼层1</a>
              <a class="tabs-nav" href="#floor2">楼层楼层2</a>
              <a class="tabs-nav" href="#floor3">楼层楼层3</a>
              <a class="tabs-nav" href="#floor4">楼层楼层4</a>
              <a class="tabs-nav" href="#floor5">楼层楼层5</a>
              <a class="tabs-nav" href="#floor6">楼层楼层6</a> -->
            </div>
          </div>
          <template v-if="this.component && this.component.base && this.component.base[0].val">
            <div class="floor-icon-shadow"></div>
            <div class="floor-icon-down" :style="{ backgroundColor }">
              <span class="icon">&#xe629;</span>
            </div>
          </template>
        </div>
        <div class="floor-more" :style="{ display: 'none', backgroundColor, borderRadius }">
          <header>
            <h3>全部楼层</h3>
            <div class="floor-icon-shadow"></div>
            <div class="floor-more-close" :style="{ backgroundColor }">
              <span class="icon">&#xe628;</span>
            </div>
          </header>
          <ul>
            <li class="more-item" v-for="(item, idx) in items" :key="idx">
              <span class="icon" :style="`display: ${idx == 0 ? 'inline-block' : 'none'};`">&#xe625;</span>
              <a :href="`#${item.click ? item.click.href : ''}`">{{item.text}}</a>
            </li>
            <!-- <li class="more-item">
              <a href="#floor1">楼层楼层1</a>
            </li>
            <li class="more-item">
              <a href="#floor2">楼层楼层2</a>
            </li>
            <li class="more-item">
              <a href="#floor3">楼层楼层3</a>
            </li>
            <li class="more-item">
              <a href="#floor4">楼层楼层4</a>
            </li>
            <li class="more-item">
              <a href="#floor5">楼层楼层5</a>
            </li>
            <li class="more-item">
              <a href="#floor6">楼层楼层6</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KuranFloorMenu",
  props: {
    component: {
      type: Object
    }
  },
  data() {
    return {
      items: this.component.action.config,
      backgroundColor: this.component.style[0].val,
      fillColor: this.component.style[2].val,
      borderRadius: this.component.style[4].val + this.component.style[4].unit,
      paddingTop: this.component.style[5].val + this.component.style[5].unit,
      paddingRight: this.component.style[6].val + this.component.style[6].unit,
      paddingBottom: this.component.style[7].val + this.component.style[7].unit,
      paddingLeft: this.component.style[8].val + this.component.style[8].unit
    };
  },
  watch: {
    component: {
      handler() {
        this.items = this.component.action.config;
        this.backgroundColor = this.component.style[0].val;
        this.fillColor = this.component.style[2].val;
        this.borderRadius =
          this.component.style[4].val + this.component.style[4].unit;
        this.paddingTop =
          this.component.style[5].val + this.component.style[5].unit;
        this.paddingRight =
          this.component.style[6].val + this.component.style[6].unit;
        this.paddingBottom =
          this.component.style[7].val + this.component.style[7].unit;
        this.paddingLeft =
          this.component.style[8].val + this.component.style[8].unit;
      },
      deep: true
    }
  },
  methods: {
    getItemStyle(idx) {
      const ret = [];
      if (idx === 0) {
        ret.push("background-color:" + this.component.style[1].val);
        ret.push("color:" + this.component.style[3].val);
      } else {
        ret.push("background-color:" + this.component.style[0].val);
        ret.push("color:" + this.component.style[2].val);
      }
      return ret.join(";");
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

  .kuran-floor-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .floor {
    position: relative;
    height: 40px;
    // border-top: 1px solid #f2f2f2;
    // border-bottom: 1px solid #f2f2f2;
  }

  .floor.floor-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .floor .floor-placeholder {
    height: 40px;
    width: 100%;
  }

  .floor .floor-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .floor .floor-inner.floor-fixed {
    position: fixed;
    z-index: 9999;
  }

  .floor .floor-inner .floor-tabs {
    position: relative;
    height: 40px;
    padding-right: 40px;
    z-index: 1;
  }

  .floor .floor-inner .floor-tabs .floor-tabs-inner,
  .floor .floor-inner .floor-tabs .floor-tabs-inner .tabs-scroll-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }

  .floor .floor-inner .floor-tabs .floor-tabs-inner {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    // background-color: #fff;
  }

  .floor .floor-inner .floor-tabs .floor-tabs-inner .tabs-scroll-list {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    // padding: 0 5px;
  }

  .floor .floor-inner .floor-tabs .floor-tabs-inner .tabs-scroll-list > a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 11px;
    font-size: 14px;
    color: #666666;
    text-decoration: none;
    text-align: center;
  }

  .floor .floor-inner .floor-more ul .more-item.active > a,
  .floor
    .floor-inner
    .floor-tabs
    .floor-tabs-inner
    .tabs-scroll-list
    > a.active {
    color: #d0021b;
    font-weight: 500;
  }
  .floor .floor-inner .floor-more ul .more-item .icon,
  .floor .floor-inner .floor-tabs .floor-tabs-inner .tabs-scroll-list > a .icon{
      display: none;
  }
  .floor .floor-inner .floor-more ul .more-item.active .icon,
  .floor .floor-inner .floor-tabs .floor-tabs-inner .tabs-scroll-list > a.active {
    display: inline-block;
  }

  .floor .floor-inner .floor-icon-shadow {
    // position: absolute;
    // right: 40px;
    // top: 0;
    // width: 0.64rem;
    // height: 40px;
    width: 8px;
    height: 40px;
    background: linear-gradient(90deg,rgba(244,246,246,0) 0%,rgba(232,232,232,1) 100%);
    position: absolute;
    right: 40px;
    top: 0;
    z-index: 2;
  }

  .floor .floor-inner .floor-icon-down {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fff;
  }

  .floor .floor-inner .floor-icon-down > .icon {
    font-weight: bold;
  }

  .floor .floor-inner .floor-more {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    overflow: hidden;
  }

  .floor .floor-inner .floor-more > header {
    position: relative;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    // background-color: #fff;
  }

  .floor .floor-inner .floor-more > header:after {
    pointer-events: none;
    position: absolute;
    content: "";
    height: 1px;
    background: #f2f2f2;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .floor .floor-inner .floor-more > header > h3 {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    text-align: left;
    margin: 0;
  }

  .floor .floor-inner .floor-more > header .floor-more-close {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    right: 0;
    top: 0;
  }

  .floor .floor-inner .floor-more > header .floor-more-close > .icon {
    font-weight: bold;
  }

  .floor .floor-inner .floor-more ul {
    padding: 0.42667rem 0;
    background-color: #F4F6F6;
    font-size: 0;
  }

  .floor .floor-inner .floor-more ul .more-item {
    display: inline-block;
    padding: 0 11px;
    text-align: center;
  }

  .floor .floor-inner .floor-more ul .more-item > a {
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #4a4a4a;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .floor .icon {
    font-family: iconfont;
  }
</style>
