/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-07-06 18:36:28
 */
/**
 * 注册自定义组件
 */

import BottomMenu from './general/bottom-menu'
import FloorMenu from './general/floor-menu'
import BaseForm from './general/form'
import GridMenu from './general/grid-menu'
import BaseImage from './general/image'
import BaseText from './general/text'
import Timeout from './general/timeout'

import ScrollLeft from './swiper/scroll-left'
import SwiperBanner from './swiper/swiper-banner'

import HorizontalList from './list/horizontal-list'
import VerticalList from './list/vertical-list'

import PageFaq from './pageModule/page-faq'
import PageIntro from './pageModule/page-intro'
import PageParagraph from './pageModule/page-paragraph'
import PageTitle from './pageModule/page-title'

import NewsMarquee from './animate/news-marquee'

// app 首页配置组件
import HotSale from './apphome/hot-sale.vue'
import DouyinHotSale from './apphome/douyin-hot-sale.vue'
import HighCommission from './apphome/high-commission.vue'
import HigtDiscount from './apphome/high-discount.vue'
import RecommendGoods from './apphome/recommend-goods.vue'
import AppSwiper from './apphome/app-swiper.vue'
import SplitImage from './apphome/split-image.vue'
import KingKong from './apphome/king-kong.vue'
import VGoodsBang from './apphome/v-goods-bang.vue'

// h5
import KuranParagraph from './kuran/kuran-paragraph.vue'
import KuranGoodsFullwidth from './kuran/kuran-goods-fullwidth.vue'
import KuranTitle from './kuran/kuran-title.vue'
import KuranForm from './kuran/kuran-form.vue'
import KuranFloorMenu from './kuran/kuran-floor-menu.vue'
import KuranSplitImage from './kuran/split-image.vue'
import KuranSwiper from './kuran/kuran-swiper.vue'

export default {
  install(Vue) {
    Vue.component(BottomMenu.name, BottomMenu)
    Vue.component(FloorMenu.name, FloorMenu)
    Vue.component(BaseForm.name, BaseForm)
    Vue.component(GridMenu.name, GridMenu)
    Vue.component(BaseImage.name, BaseImage)
    Vue.component(BaseText.name, BaseText)
    Vue.component(Timeout.name, Timeout)
    Vue.component(NewsMarquee.name, NewsMarquee)
    Vue.component(ScrollLeft.name, ScrollLeft)
    Vue.component(SwiperBanner.name, SwiperBanner)
    Vue.component(HorizontalList.name, HorizontalList)
    Vue.component(VerticalList.name, VerticalList)
    Vue.component(PageFaq.name, PageFaq)
    Vue.component(PageIntro.name, PageIntro)
    Vue.component(PageParagraph.name, PageParagraph)
    Vue.component(PageTitle.name, PageTitle)

    // app 首页配置组件
    Vue.component(HotSale.name, HotSale)
    Vue.component(DouyinHotSale.name, DouyinHotSale)
    Vue.component(HighCommission.name, HighCommission)
    Vue.component(HigtDiscount.name, HigtDiscount)
    Vue.component(RecommendGoods.name, RecommendGoods)
    Vue.component(AppSwiper.name, AppSwiper)
    Vue.component(SplitImage.name, SplitImage)
    Vue.component(KingKong.name, KingKong)
    Vue.component(VGoodsBang.name, VGoodsBang)


    // h5
    Vue.component(KuranParagraph.name, KuranParagraph)
    Vue.component(KuranGoodsFullwidth.name, KuranGoodsFullwidth)
    Vue.component(KuranTitle.name, KuranTitle)
    Vue.component(KuranForm.name, KuranForm)
    Vue.component(KuranFloorMenu.name, KuranFloorMenu)
    Vue.component(KuranSplitImage.name, KuranSplitImage)
    Vue.component(KuranSwiper.name, KuranSwiper)
    
  }
}
