<!--
 * @Description: 折一标题组件（出现在表单顶部，商品列表顶部等位置）
 * @Date: 2020-05-18 10:27:01
 * @LastEditTime: 2020-05-18 12:30:09
 * @LastEditors: 白青
-->
<template>
  <div :class="['comp-content', component.active ? 'active' : '']" :style="getStyle">
    <div class="kuran-title">
      <div v-for="(item, idx) in component.action.config" :key="idx">
        <span class="icon">&#xe626;</span>
        <span class="kuran-title-text">{{ item.content }}</span>
        <span class="icon">&#xe627;</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KuranTitle',
  props: {
    component: {
      type: Object,
    },
  },
  computed: {
    getStyle() {
      const ret = [];
      this.component.style.forEach(item => {
        const unit = item.unit || '';
        if (item.val) {
          if (item.attr === 'background-image') {
            ret.push(item.attr + ':url(' + item.val + ')');
          } else {
            ret.push(item.attr + ':' + item.val + unit);
          }
        }
      });
      return ret.join(';');
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.kuran-title {
  height: 53px;
  line-height: 53px;
  text-align: center;
}

.kuran-title .kuran-title-text {
  font-size: 18px;
  font-weight: bold;
}
.kuran-title .icon {
  font-family: iconfont;
  font-size: 16px;
  display: inline-block;
  margin: 0 4px;
}
</style>
