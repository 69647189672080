<!--
 * @Description: 金刚位
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-11 16:55:32
 * @LastEditTime: 2020-07-14 19:53:31
 -->
<template>
  <!-- <div class="app-home-d-block"> -->
  <div class="kingkong-box app-home-d-block" :style="getStyle">
    <div v-for="item in component.action.config" class="kk-item" :key="item.imgUrl">
      <div class="kingkong-image-placeholder" :style="imgStyle(item.width, item.height)">
        <img
          v-if="item.imgUrl"
          :src="item.imgUrl"
          :key="item.imgUrl"
          :style="imgStyle(item.width, item.height)"
        />
        <img
          v-else
          :style="imgStyle(item.width, item.height)"
          src="https://oss.zheyi360.com/app-image/topic/default-goods.png"
        />
      </div>
      <div :style="imgWidth()" class="label-box">
        <div v-if="item.label" :style="{ color: component.style[0].val }">{{ item.label }}</div>
        <div v-else style="color: #aaa">未填写</div>
      </div>
    </div>
    <!--图片占位-->
    <div class="kk-item" v-for="i in placeholderItems" :key="i" :style="imgWidth()"></div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'KingKong',
  props: {
    component: {
      type: Object,
    },
    isInHome: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      imgUrls: this.component.config,
      split: this.component.base[0].val,
    };
  },
  methods: {
    imgStyle(w, h) {
      const _w = (750 - 60) / this.component.base[0]['val'] - 12;
      const _h = (_w * h) / w;
      return `width:${_w / 2}px;height:${_h / 2}px`;
    },
    imgWidth() {
      const _w = (750 - 60) / this.component.base[0]['val'] - 12;
      return `width:${_w / 2}px;`;
    },
  },
  computed: {
    getStyle() {
      const ret = [];
      this.component.style.forEach(item => {
        if (item.val) {
          if (Array.isArray(item.attr)) {
            item.attr.forEach((atr, i) => {
              ret.push(atr + ':' + item.val[i]);
            });
          } else if (item.attr === 'background-image') {
            // ret.push(item.attr + ':url(' + item.val + ')')
          } else {
            const unit = item.unit || '';
            ret.push(item.attr + ':' + item.val + unit);
          }
        }
      });
      return ret.join(';');
    },
    placeholderItems() {
      const ret = [];
      let count = 0;
      count =
        this.component.base[0].val -
        (this.component.action.config.length % this.component.base[0].val);
      ret.length = count;
      return ret;
    },
  },
  // watch: {
  //   component: {
  //     handler() {
  //       this.imgUrl = this.component.style[1].val
  //     },
  //     deep: true
  //   }
  // }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.kingkong-box {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 3px;
  padding: 6px;
  justify-content: space-between;
  margin: 0 15px;
  .kk-item {
    // margin-bottom: 8px;
    a,
    a:hover {
      text-decoration: none;
    }
  }
  .kingkong-image-placeholder {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > .fa {
      color: #83c0ff;
      font-size: 40px;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    border: 0;
    -webkit-user-drag: none;
  }
  .label-box {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}
</style>
