<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-05-17 18:15:06
 -->
<template>
  <div class="app-home-d-block">
    <div :class="['default-c-box', component.action.config.isShow ? '' : 'block-hide']">
      <img src="https://oss.zheyi360.com/app-image/home-config/highDiscount.png" alt="高折扣榜" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighDiscount',
  props: {
    component: {
      type: Object,
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.comp-content {
  background-repeat: no-repeat;
}
.text-box {
  display: block;
}
</style>
