<!--
 * @Description: 折一段落组件
 * @Date: 2020-05-15 13:45:53
 * @LastEditTime: 2020-07-15 16:00:53
 * @LastEditors: 前胡
-->
<template>
  <!-- <div :class="['comp-content', component.active ? 'active' : '']" :style="getStyle"> -->
  <div class="kuran-goods-fullwidth-list" :class="splitClass" :style="getLayoutStyle">
    <template>
      <div class="kuran-goods-fullwidth-mod" v-for="(goods, idx) in goodsItems" :key="idx">
        <div>
          <div class="goods-pic">
            <img
              style="box-shadow: 0 2px 8px 0 rgba(101, 88, 88, 0.1)"
              v-if="
                goods &&
                goods.tempGoodsInfo &&
                (goods.tempGoodsInfo.item_id__white_image || goods.tempGoodsInfo.item_id__pict_url)
              "
              :src="
                goods.tempGoodsInfo.item_id__white_image || goods.tempGoodsInfo.item_id__pict_url
              "
            />
            <img v-else src="https://oss.zheyi360.com/app-image/topic/default-goods.png" />
            <img
              v-if="component.base[0].val === 1 && goods.tempGoodsInfo.is_support_send"
              style="position: absolute; width: 48px; height: 48px; top: -2px; left: -2px"
              src="../../../assets/home_mfjy_label_icon@2x.png"
            />
            <img
              mode="aspectFit"
              fit="fill"
              v-if="component.base[0].val !== 1 && goods.tempGoodsInfo.is_support_send"
              style="position: absolute; width: 60px; height: 60px; top: -2px; left: -2px"
              src="../../../assets/home_mfjy_label_icon@2x.png"
            />
          </div>
        </div>
        <div class="goods-info">
          <div class="goods-title-tag">
            <div class="goods-tags">
              <div class="goods-title-item">
                <h3>
                  <span v-if="titletag" class="title-tag">{{
                    titletag ? titletag : goods.special_tag
                  }}</span>
                  {{ goods.goodsName }}
                </h3>
              </div>
            </div>
          </div>
          <div class="goods-title-tag">
            <div class="goods-tags" style="height: 20px !important; margin-top: 5px">
              <div
                class="goods-tags-item"
                :style="getTagStyle"
                v-if="component.base[0].val !== 3 && goods.tag1"
              >
                {{ goods.tag1 }}
              </div>
              <div
                class="goods-tags-item"
                :style="getTagStyle"
                v-if="component.base[0].val === 1 && goods.tag2"
              >
                {{ goods.tag2 }}
              </div>
            </div>
          </div>
          <div class="goods-pirce-mod" style="margin-top: 8px" v-if="component.base[0].val === 1">
            <div class="goods-commission">
              <div class="expect-new-price">
                <div style="display: block; width: 100%">
                  <span>¥</span
                  >{{ pass10k(goods && goods.tempGoodsInfo && goods.tempGoodsInfo.show_price) }}
                </div>
              </div>
            </div>
            <div class="goods-commission">
              <div class="expect-price">
                {{
                  ((goods && goods.tempGoodsInfo && goods.tempGoodsInfo.show_commission_rate) ||
                    0) / 100
                }}%
              </div>
            </div>
            <div class="goods-commission">
              <div class="expect-price">
                <div style="display: block; width: 100%">
                  <span>¥</span
                  >{{
                    pass10k(goods && goods.tempGoodsInfo && goods.tempGoodsInfo.show_commission)
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="goods-pirce-mod" v-if="component.base[0].val === 1">
            <div class="goods-commission">
              <div class="expect-new-price">
                <div style="display: block; width: 100%">直播价</div>
              </div>
            </div>
            <div class="goods-commission">
              <div class="expect-new-price">
                <span>佣金率</span>
              </div>
            </div>
            <div class="goods-commission">
              <div class="expect-new-price">
                <span>预估赚</span>
              </div>
            </div>
          </div>
          <div
            v-if="component.base[0].val === 2 || component.base[0].val === 3"
            class="goods-pirce-mod"
            style="margin-top: 5px"
          >
            <div class="goods-commission" style="width: 100%; position: relative">
              <div style="">
                售价 ¥{{ pass10k(goods && goods.tempGoodsInfo && goods.tempGoodsInfo.show_price) }}
              </div>
              <div style="float: right; position: absolute; right: 10px">
                佣金
                {{
                  ((goods && goods.tempGoodsInfo && goods.tempGoodsInfo.show_commission_rate) ||
                    0) / 100
                }}%
              </div>
            </div>
          </div>
          <div
            v-if="component.base[0].val === 2 || component.base[0].val === 3"
            class="goods-pirce-mod"
          >
            <div class="goods-commission" style="width: 100%; position: relative">
              <div style="">已售：{{ formatNumber(goods.tempGoodsInfo.item_id__volume || 0) }}</div>
              <div
                class="expect-price"
                style="
                  float: right;
                  position: absolute;
                  right: 10px;
                  background-color: #f43324;
                  color: #ffffff;
                  border-radius: 40px;
                  padding: 0px 10px;
                  line-height: 20px;
                  height: 20px;
                "
              >
                <span>赚 ¥</span
                >{{ pass10k(goods && goods.tempGoodsInfo && goods.tempGoodsInfo.show_commission) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-for="i in blankGoodsCount">
        <div class="blank-goods" :key="'blank' + i" v-if="component.base[0].val !== 1"></div>
      </template>
    </template>
  </div>
  <!-- </div> -->
</template>

<script>
import util from '@/utils/h5-marker-util.js';
export default {
  name: 'KuranGoodsFullwidth',
  props: {
    component: {
      type: Object,
    },
    compList: {
      type: Array,
      default: () => [],
    },
    option: {
      type: Object,
    },
  },
  data() {
    return {
      compnewList: this.compList,
      titletag: '',
      formatNumber: util.formatNumber,
      goodsItems: this.component.action.config,
    };
  },
  watch: {
    component: {
      handler: function (val) {
        this.goodsItems = val.action.config;
      },
      deep: true,
    },
    option: {
      handler: function (val) {
        this.titletag = val.base[1].attr === 'page-tag' ? val.base[1].val : '';
        this.compnewList = this.compnewList.map(item => {
          if (item.type === 'kuran-goods-fullwidth' && item.action.config) {
            item.action.config = item.action.config.map(subitem => {
              subitem.special_tag = this.titletag;
              return subitem;
            });
          }
          return item;
        });
        this.$emit('sutmitTocompList', this.compnewList);
      },
      deep: true,
    },
  },
  created() {
    this.titletag = this.option.base[1].attr === 'page-tag' ? this.option.base[1].val : '';
    if (this.titletag != '') {
      this.compnewList = this.compnewList.map(item => {
        if (item.type === 'kuran-goods-fullwidth' && item.action.config) {
          item.action.config = item.action.config.map(subitem => {
            subitem.special_tag = this.titletag;
            return subitem;
          });
        }
        return item;
      });
      this.$emit('sutmitTocompList', this.compnewList);
    }
  },
  methods: {
    // 价格超过1万，显示x.x万
    pass10k(val) {
      let vStr = !isNaN(val) ? val : 0;
      if (vStr > 10000) {
        vStr = Math.floor((val / 1000) * 10) / 10 + '万';
      }
      return vStr;
    },
  },
  computed: {
    getLayoutStyle() {
      const ret = [];
      this.component.style.forEach(item => {
        const unit = item.unit || '';
        if (item.val && (item.attr.indexOf('margin') > -1 || item.attr.indexOf('padding') > -1)) {
          ret.push(item.attr + ':' + item.val + unit);
        }
      });
      return ret.join(';');
    },
    getTagStyle() {
      const ret = [];
      this.component.style.forEach(item => {
        const unit = item.unit || '';
        if (item.val && (item.attr === 'background' || item.attr === 'color')) {
          ret.push(item.attr + ':' + item.val + unit);
        }
      });
      return ret.join(';');
    },
    splitClass() {
      return `goods-col-${this.component.base[0].val}`;
    },
    // 补充的空白商品
    blankGoodsCount() {
      let count = 0;
      const residue = this.component.action.config.length % this.component.base[0].val;
      count = this.component.base[0].val - (residue == 0 ? this.component.base[0].val : residue);
      return count;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.kuran-goods-fullwidth-list {
  .kuran-goods-fullwidth-mod {
    background: #fff;
    border-radius: 3px;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    .goods-pic {
      position: relative;
      width: 105px;
      height: 105px;
      margin-right: 8px;
      img {
        position: absolute;
        display: block;
        width: 105px;
        height: 105px;
        border-radius: 5px;
      }
    }
    .goods-info {
      margin-top: -8px;
      height: 110px;
      flex-grow: 1;
      flex-direction: column;
      height: 90px;
      display: flex;
      justify-content: space-between;
      .goods-title-tag {
        h3 {
          font-weight: normal;
          font-size: 14px;
          color: #333;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0;
        }
        .title-tag {
          display: inline-block;
          position: relative;
          padding: 0px 4px;
          background-image: linear-gradient(270deg, #ff6637 0%, #f81f0f 100%);
          border-radius: 4px;
          margin-right: 1px;
          font-family: PingFangSC-Regular;
          font-weight: 500;
          font-size: 10px;
          color: #ffffff;
          text-align: center;
        }
        .goods-tags {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
          .coupon-tag {
            align-items: center;
            margin-top: 5px;
            color: #fff;
            font-size: 11px;
            display: flex;
            height: 18px;
            line-height: 18px;
            padding: 0 4px;
            margin-right: 8px;
          }
          .goods-tags-item {
            margin-right: 8px;
            background: #fdebdc;
            border-radius: 4px;
            padding: 0px 4px;
            font-family: PingFangSC-Regular;
            font-weight: 500;
            font-size: 9px;
            color: #f43324;
            border: 1px solid rgba(244, 51, 36, 0.5);
            line-height: 16px;
            height: 18px;
          }
        }
      }
      .goods-pirce-mod {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 12px;
        .goods-price {
          width: 70%;
          .del-line {
            color: #c0c0c0;
            font-size: 12px;
            text-decoration: line-through;
          }
          color: #333333;
          font-size: 12px;
          margin-right: 6px;
          span {
            font-size: 12px;
            color: #333333;
          }
        }
        .goods-commission {
          width: 30%;
          /*border: 1px solid #eb2d54;*/
          border-radius: 4px;
          display: flex;
          align-items: center;
          height: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
          .expect-price {
            background-size: contain;
            padding-left: 8px;
            padding-right: 6px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            font-size: 13px;
            height: 18px;
            line-height: 18px;
            font-weight: 700;
            color: #f43324;
            display: flex;
            align-items: left;
            span {
              font-size: 10px;
              font-weight: 400;
            }
          }
          .expect-new-price {
            background-size: contain;
            padding-left: 8px;
            padding-right: 6px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            font-size: 10px;
            height: 18px;
            line-height: 18px;
            font-weight: 500;
            color: #333333;
            display: flex;
            align-items: left;
            span {
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  &.goods-col-1 {
    .kuran-goods-fullwidth-mod {
    }
  }
  &.goods-col-2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 12px 12px 0px 0px;
    margin: 0 7px;
    .kuran-goods-fullwidth-mod,
    .blank-goods {
      padding: 0;
      width: calc(50% - 10px);
      margin: 0px 5px 10px;
      display: block;
      .goods-pic {
        margin: 0 auto 10px;
        width: 100%;
        height: 150px;
        position: relative;
        img {
          margin-top: 0px;
          position: absolute;
          display: block;
          width: 100%;
          height: 150px;
          border-radius: 12px 12px 0px 0px;
        }
      }
      .goods-info {
        padding: 0 10px 10px 10px;
        flex-grow: 1;
        flex-direction: column;
        height: 115px;
        display: flex;
        justify-content: space-between;
        .goods-title-tag {
          h3 {
            line-height: 17px;
            font-size: 12px;
          }
        }
        .goods-pirce-mod {
          margin-top: 2px;
          display: flex;
          align-items: center;
          .goods-price {
            strong {
              display: block;
              margin-right: 0;
              span {
                font-size: 16px;
              }
            }
          }
          .goods-commission {
            width: 22%;
            /*border: 1px solid #eb2d54;*/
            border-radius: 4px;
            display: flex;
            align-items: center;
            height: 18px;
            line-height: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            .expect-price {
              background-size: contain;
              padding-left: 8px;
              padding-right: 6px;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
              font-size: 12px;
              height: 18px;
              line-height: 18px;
              font-weight: 700;
              color: #f43324;
              display: flex;
              align-items: left;
              span {
                font-size: 10px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
  &.goods-col-3 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 8px 8px 0px 0px;
    margin: 0 10px;
    .kuran-goods-fullwidth-mod,
    .blank-goods {
      padding: 0;
      width: calc(33.33% - 4px);
      margin: 0 2px 4px;
      display: block;
      .goods-pic {
        margin: 0 auto 10px;
        width: 100%;
        height: auto;
        img {
          display: block;
          width: 100%;
          height: 120px;
          border-radius: 8px 8px 0px 0px;
        }
      }
      .goods-info {
        padding: 0 0px 6px 0px;
        flex-grow: 1;
        flex-direction: column;
        height: 80px;
        display: flex;
        justify-content: space-between;
        .goods-title-tag {
          h3 {
            font-weight: normal;
            font-size: 12px;
            color: #333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin-bottom: 0;
          }

          .goods-tags {
          }
        }
        .goods-pirce-mod {
          margin-top: 2px;
          display: flex;
          padding: 0 0 0 3px;
          align-items: flex-start;
          .goods-price {
            white-space: nowrap;
            display: inline-block;
            margin-right: 0;
            span {
              font-size: 12px;
            }
          }
          .goods-commission {
            height: 20px;
            line-height: 20px;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
