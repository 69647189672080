
export default {
  createDomID(len) {
    return Number(Math.random().toString().substr(3, len)).toString(36)
  },
  copyObj(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  // 格式化价格
formatNumber(number, unitName = '万')  {
    if (number >= 10000) {
      let result = (number / 10000).toString();
      if (result.includes('.')) {
        let newNumberStr = `${result.split('.')[0]}.${result.split('.')[1].substr(0, 2)}`;
        newNumberStr = newNumberStr.replace('.00', '');
        if (newNumberStr[newNumberStr.length - 1] == '0')
          newNumberStr = newNumberStr.slice(0, newNumberStr.length - 1);
        return `${newNumberStr}${unitName}`;
      } else {
        return `${result}${unitName}`;
      }
    } else {
      return number; // + ' '
    }
  },
parseTime(time, cFormat) {
    if (arguments.length === 0) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
        time = parseInt(time)
      }
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  }
}
