<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-11 16:55:32
 * @LastEditTime: 2020-05-18 10:53:23
 -->
<template>
  <div class="app-home-d-block">
    <div class="image-box">
      <template v-for="item in component.action.config">
      <img v-if="item.imgUrl" :src="item.imgUrl" :key="item.imgUrl" :style="imgStyle(item.width, item.height)">
      </template>
      <div v-if="!component.action.config[0].imgUrl.length" class="image-placeholder">
        <i class="fa fa-image" v-for="i in this.component.base[0].val" :key="i"></i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SplitImage',
    props: {
      component: {
        type: Object
      }
    },
    data() {
      return {
        imgUrls: this.component.config,
        split: this.component.base[0].val
      }
    },
    methods:{
      imgStyle(w, h){
        const _w = (750-60)/ this.component.base[0]['val'];
        const _h = _w * h / w;
        return `width:${_w/2}px;height:${_h/2}px`
      }
    }
    // watch: {
    //   component: {
    //     handler() {
    //       this.imgUrl = this.component.style[1].val
    //     },
    //     deep: true
    //   }
    // }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .image-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
 

    .image-placeholder {
      width: 100%;
      height: 100px;
      line-height: 100px;
      text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
      > .fa {
        color: #83c0ff;
        font-size: 40px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      border: 0;
      -webkit-user-drag: none;
    }
  }
</style>
